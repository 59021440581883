var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6 text-center mx-auto mb-5"
  }, [_c('img', {
    class: _vm.retiredChatPage ? 'mt-5' : 'image-404',
    attrs: {
      "src": require("@/assets/images/404.png")
    }
  }), _vm.retiredChatPage ? _c('div', [_c('h1', [_vm._v(" " + _vm._s(_vm.$t('tavernDiscontinued')) + " ")]), _c('p', [_vm._v(_vm._s(_vm.$t('tavernDiscontinuedDetail')))]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('tavernDiscontinuedLinks'))
    }
  })]) : _c('div', [_c('h1', [_vm._v(" Sometimes even the bravest adventurer gets lost. ")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Looks like this link is broken or the page may have moved, sorry! ")]), _c('p', [_vm._v(" Head back to the "), _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v(" Homepage ")]), _vm._v("or "), _c('a', {
    attrs: {
      "href": "mailto:admin@habitica.com"
    }
  }, [_vm._v(" Contact Us ")]), _vm._v("about the issue. ")], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }